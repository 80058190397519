<template>
  <keep-alive>
    <component
      :is="current"
      v-bind="params"
      @edit="onEdit"
      @new="onNew"
      @close="onClose"
    />
  </keep-alive>
</template>

<script>
import customerList from '@/views/dashboard/components/customers/customerList';
import customerForm from '@/views/dashboard/components/customers/customerForm';

export default {
  components: {},
  data: function() {
    return {
      current: null,
      params: {}
    };
  },
  computed: {},
  watch: {},
  async mounted() {
    this.current = customerList;
    this.params = {};
  },
  methods: {
    onNew() {
      this.current = customerForm;
      this.params = {
        value: {
          id: '',
          name: '',
          short_name: '',
          external_id: '',
          types: [],
          nip: '',
          regon: '',
          due_days: null,
          default_varnish: '',
          addresses: []
        }
      };
    },
    onEdit(item) {
      this.current = customerForm;
      this.params = {
        value: item
      };
    },
    onClose() {
      this.current = customerList;
      this.params = {};
    }
  }
};
</script>

<style></style>
